.main{
    display: flex;
    height: 24px;
    font-size: 12px;
    width: max-content;
    border-radius: 4px;
    align-items: center;
    padding-left: 6px;
    padding-right: 10px;
}

.badgeContainer {
    display: flex;
    gap: 10px;
    white-space: nowrap;
    width: fit-content;
    justify-content: flex-start;
    flex-wrap: wrap;
}